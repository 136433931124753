<div
  class="v-menu"
  [ngClass]="{
    'v-menu-small': size === 'small',
    'v-menu-medium': size === 'medium',
    'v-menu-large': size === 'large',
    'v-menu-horizontal': direction === 'horizontal'
  }">
  <ul>
    @for (item of items; track item.label; let last = $last) {
      @if (item.items) {
        <h3 class="text-primary font-semibold pb-3 px-3">
          {{ item.label | translate }}
        </h3>
        <div
          [ngClass]="{
            'mb-5': !last
          }">
          <v-menu [items]="item.items"></v-menu>
        </div>
      } @else {
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="
            item.routerLinkActiveOptions || { exact: false }
          ">
          <a [routerLink]="item.routerLink" (click)="onClick(item)">
            <div class="v-menu-item-wrapper">
              <i [class]="'pi ' + item.icon" *ngIf="item.icon"></i>
              <img
                [src]="item.image"
                *ngIf="item.image"
                [alt]="item.label | translate" />
              <span>{{ item.label | translate }}</span>
            </div>
          </a>
        </li>
      }
    }
  </ul>
</div>

import { inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { Service } from 'src/app/models/service';

export const GET_SERVICES_QUERY = gql`
  query GetServices($organizationUUID: ID!) {
    services(organizationUUID: $organizationUUID) {
      id
      name
      description
      owner {
        name
      }
      state
    }
  }
`;

const GET_SERVICE = gql`
  query GetService($organizationUUID: ID!, $serviceId: Int!) {
    service(organizationUUID: $organizationUUID, serviceId: $serviceId) {
      id
      name
      description
      incidents {
        id
        title
        priority {
          id
          name
          order
          color
          icon
        }
        state {
          id
          name
          order
          color
          icon
          isDefault
          isResolved
        }
      }
      owner {
        id
        name
      }
    }
  }
`;

const UPDATE_SERVICE_MUTATION = gql`
  mutation UpdateService(
    $organizationUUID: ID!
    $serviceId: Int!
    $updateServiceSpec: UpdateServiceSpec!
  ) {
    updateService(
      organizationUUID: $organizationUUID
      serviceId: $serviceId
      updateServiceSpec: $updateServiceSpec
    ) {
      name
      description
      owner {
        id
        name
      }
    }
  }
`;

const DELETE_SERVICE_MUTATION = gql`
  mutation DeleteService($organizationUUID: ID!, $serviceId: Int!) {
    deleteService(organizationUUID: $organizationUUID, serviceId: $serviceId)
  }
`;

@Injectable()
export class ServicesService {
  private apollo = inject(Apollo);

  loadServices(organizationId: string): Observable<Service[]> {
    return this.apollo
      .query<{ services: Service[] }>({
        query: GET_SERVICES_QUERY,
        variables: {
          organizationUUID: organizationId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.services;
        })
      );
  }

  loadService(organizationId: string, serviceId: number): Observable<Service> {
    return this.apollo
      .query<{ service: Service }>({
        query: GET_SERVICE,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.service;
        })
      );
  }

  updateService(
    organizationId: string,
    serviceId: number,
    updateSpec: {
      name?: string;
      description?: string;
      ownerTeamPublicId?: number;
    }
  ): Observable<Partial<Service>> {
    return this.apollo
      .mutate<{ updateService: Service }>({
        mutation: UPDATE_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
          updateServiceSpec: {
            name: updateSpec.name,
            description: updateSpec.description,
            owningTeamID: updateSpec.ownerTeamPublicId,
          },
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.updateService;
        })
      );
  }

  deleteService(
    organizationId: string,
    serviceId: number
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteService: boolean }>({
        mutation: DELETE_SERVICE_MUTATION,
        variables: {
          organizationUUID: organizationId,
          serviceId: serviceId,
        },
      })
      .pipe(
        map(data => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data!.deleteService;
        })
      );
  }
}

/**
 * Production Configuration
 */
export const environment = {
  sentry: {
    enabled: true,
    dsn: 'https://eb8937a958fd43a264023fc6571b3ba5@o4506509273792512.ingest.sentry.io/4506509274841088',
    environment: 'production',
  },
  frontendUrl: 'https://app.incidite.com',
  kratosEndpoint: 'https://auth.app.incidite.com',
  gqlEndpoint: 'https://app.incidite.com/api/query',
  gqlEndpointWS: 'wss://app.incidite.com/api/query',
  stripeToken:
    'pk_live_51OK4nDHNayy038zWwrieCvKLVaPHhEhBEdMSpfPsvPTRSmbnXJQCioAPujXt4w2cLZkAg0Qe7fER8FUUtTZpOhxg00fwgRfzPZ',
  productPriceIds: {
    starter: {
      monthly: 'price_1OfOnEHNayy038zWimi75MYi',
      yearly: 'price_1OfOnEHNayy038zWLQhPDJy8',
    },
    pro: {
      monthly: 'price_1OfOnEHNayy038zWl7VzjbDH',
      yearly: 'price_1OfOnEHNayy038zWPGSKy9SI',
    },
  },
  notificationProviders: {
    slackClientId: '5010000972898.6542777016948',
    discordClientId: '1204214467303571536',
  },
  statuspages: {
    cNameUrl: 'statuspages.incidite.com.',
    hostedStatuspageDomain: 'sp.incidite.com',
  },
  posthog: {
    enabled: true,
    key: 'phc_oTgcNC1uls41qOyHWkBHmNXoQcJvurYxbCiEciWV9Rn',
    host: 'https://eu.i.posthog.com',
    environment: 'prod',
  },
};

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { ReplaySubject, map, takeUntil } from 'rxjs';
import { LoadServices } from 'src/app/pages/internal/services/services.actions';
import { ServicesState } from 'src/app/pages/internal/services/services.state';
import { LoadStatuspagesAction } from 'src/app/store/statuspages/statuspages.actions';
import { StatuspageState } from 'src/app/store/statuspages/statuspages.state';
import {
  CreateIncidentAction,
  IncidentCreatedAction,
  LoadIncidentSchemaVersionAction,
  LoadIncidentSchemasAction,
} from '../../../../../store/incidents/incidents.actions';
import { IncidentsState } from '../../../../../store/incidents/incidents.state';

@Component({
  selector: 'app-page-incidents-new',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.scss'],
})
export class NewIncidentPageComponent implements OnInit, OnDestroy {
  private destroy$ = new ReplaySubject<void>(1);
  private store = inject(Store);
  private dialog = inject(DialogService);
  private router = inject(Router);
  private actions$ = inject(Actions);

  constructor() {}

  newIncidentFormGroup = new FormGroup({
    schemaVersionId: new FormControl('', Validators.required),
    priority: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
    summary: new FormControl(''),
    affectedServices: new FormControl(),
    statuspages: new FormControl(),
  });

  // all incident schemas
  schemas$ = this.store.select(IncidentsState.schemas).pipe(
    map(schemas => {
      if (schemas?.entities) {
        return Object.values(schemas.entities).map(schema => {
          return {
            label: schema.data?.name,
            value: schema.data?.currentVersion?.id,
          };
        });
      } else {
        return [];
      }
    })
  );

  // all avaiable services
  services$ = this.store.select(ServicesState.services).pipe(
    map(services => {
      if (services?.entities) {
        return Object.values(services.entities).map(services => {
          return {
            name: services.data?.name,
            id: services.data?.id,
          };
        });
      } else {
        return [];
      }
    })
  );

  // all avaiable statuspages
  statuspages$ = this.store.select(StatuspageState.statuspages).pipe(
    map(statuspages => {
      if (statuspages?.entities) {
        return Object.values(statuspages.entities).map(statuspage => {
          return {
            name: statuspage.data?.name,
            id: statuspage.data?.id,
          };
        });
      } else {
        return [];
      }
    })
  );

  schemaVersion$ = this.store.select(IncidentsState.currentSchemaVersion);

  close() {
    this.dialog.dialogComponentRefMap.forEach(d => d.destroy());
  }

  async onFormSubmit() {
    this.store.dispatch(
      new CreateIncidentAction({
        schemaVersionId: this.newIncidentFormGroup.value.schemaVersionId!,
        priorityId: this.newIncidentFormGroup.value.priority!,
        title: this.newIncidentFormGroup.value.title!,
        summary: this.newIncidentFormGroup.value.summary!,
        affectedServiceIds: this.newIncidentFormGroup.value.affectedServices,
        statuspageIds: this.newIncidentFormGroup.value.statuspages,
      })
    );
  }

  ngOnInit() {
    this.newIncidentFormGroup.reset();

    // redirect to incident page after creation
    this.actions$
      .pipe(ofActionSuccessful(IncidentCreatedAction), takeUntil(this.destroy$))
      .subscribe(({ incidentId }) => {
        this.router.navigate(['/incidents', incidentId]);
        this.close();
      });

    // load schema version when schema is selected
    this.newIncidentFormGroup.controls.schemaVersionId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(schemaVersionId => {
        if (!schemaVersionId) return;
        this.store.dispatch(
          new LoadIncidentSchemaVersionAction(schemaVersionId)
        );
      });

    // when schemas are loaded, select the first one by default
    this.schemas$.pipe(takeUntil(this.destroy$)).subscribe(schemas => {
      if (
        !this.newIncidentFormGroup.controls.schemaVersionId.value &&
        schemas.length > 0
      ) {
        this.newIncidentFormGroup.controls.schemaVersionId.setValue(
          schemas[0].value!
        );
      }
    });

    this.schemaVersion$
      .pipe(takeUntil(this.destroy$))
      .subscribe(schemaVersion => {
        if (schemaVersion.data?.priorities) {
          this.newIncidentFormGroup.controls.priority.setValue(
            schemaVersion.data.priorities[0].id
          );
        }
      });

    // load all required data
    this.store.dispatch(new LoadStatuspagesAction());
    this.store.dispatch(new LoadServices());
    this.store.dispatch(new LoadIncidentSchemasAction());
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}

<div>
  <header class="flex flex-col px-3 pt-3 pb-1">
    <div class="flex justify-between items-center flex-1">
      <h4 class="font-medium text-gray-200 m-0 p-0">
        {{ header | translate }}
      </h4>
      <div class="text-gray-200 text-sm">
        {{ subheader | translate }}
      </div>
    </div>
    <div class="flex h-8 items-center">
      <p
        class="{{
          getFontSizeClass()
        }} font-bold m-0 p-0 v-stats-current-value">
        {{ currentValue() }}
      </p>
    </div>
  </header>
  <div class="vops-stats-chart">
    <apx-chart
      #chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [stroke]="chartOptions.stroke!"
      [markers]="chartOptions.markers!"
      [grid]="chartOptions.grid!"
      [yaxis]="chartOptions.yaxis!"
      [fill]="chartOptions.fill!"
      [tooltip]="chartOptions.tooltip!"></apx-chart>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';

export interface VPageChangeEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

export interface VPaginationState {
  page: number;
  pageSize: number;
}

@Component({
  selector: 'v-paginator',
  standalone: true,
  imports: [CommonModule, PaginatorModule],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class VPaginatorComponent {
  @Output() pageChange: EventEmitter<VPageChangeEvent> = new EventEmitter();

  @Input() page = 1;
  @Input() rows = 10;
  @Input() totalRecords = 0;

  @Input() rowsPerPageOptions = [10, 20, 30];

  onPageChange(event: PaginatorState) {
    this.pageChange.emit({
      first: event.first || 0,
      rows: event.rows || 0,
      page: event.page ? event.page + 1 : 1,
      pageCount: event.pageCount || 1,
    });
  }
}

import { IncidentEvent, IncidentSpec } from 'src/app/models/incident';
import {
  CursorPaginationOptions,
  OffsetPaginationOptions,
} from 'src/app/store/common/pagination';
import { ImpactTimings } from './incidents.service';

export class LoadIncidents {
  static readonly type = '[Incident] Load Incidents';
  constructor(
    public pagination: OffsetPaginationOptions,
    public query?: string
  ) {}
}

export class LoadIncidentDetailsAction {
  static readonly type = '[Incident] Load Incident Details';
  constructor(
    public id: number,
    public eventPagination?: CursorPaginationOptions
  ) {}
}

export class LoadIncident {
  static readonly type = '[Incident] Load Incident';
  constructor(public id: number) {}
}

export class CreateIncidentAction {
  static readonly type = '[Incident] Create Incident';
  constructor(public spec: IncidentSpec) {}
}

export class DeleteIncidentAction {
  static readonly type = '[Incident] Delete Incident';
  constructor(public incidentId: number) {}
}

export class IncidentCreatedAction {
  static readonly type = '[Incident] Incident Created';
  constructor(public incidentId: number) {}
}

export class UpdateSelectedIncidentTitle {
  static readonly type = '[Incident] Update Title';
  constructor(public newTitle: string) {}
}

export class UpdateSelectedIncidentSummary {
  static readonly type = '[Incident] Update Summary';
  constructor(public newSummary: string) {}
}

export class UpdateSelectedIncidentPriority {
  static readonly type = '[Incident] Update Priority';
  constructor(public newPriorityId: string) {}
}

export class UpdateSelectedIncidentState {
  static readonly type = '[Incident] Update State';
  constructor(public newStateId: string) {}
}

export class UpdateSelectedIncidentServiceImpact {
  static readonly type = '[Incident] Update Service Impact';
  constructor(
    public service: number,
    public impact: string
  ) {}
}

export class CreateIncidentCommunicationChannel {
  static readonly type = '[Incident] Create Communication Channel';
  constructor(
    public provider: string,
    public channelName: string
  ) {}
}

export class LinkServiceToIncident {
  static readonly type = '[Incident] Link Service To Incident';
  constructor(public publicServiceId: number) {}
}

export class UnLinkServiceFromIncident {
  static readonly type = '[Incident] UnLink Service From Incident';
  constructor(public publicServiceId: number) {}
}

export class LinkStatuspageToIncident {
  static readonly type = '[Incident] Link Statuspage To Incident';
  constructor(public publicStatuspageId: number) {}
}

export class UnlinkStatuspageFromIncident {
  static readonly type = '[Incident] Unlink Statuspage From Incident';
  constructor(public publicStatuspageId: number) {}
}

export class UpdateSelectedIncidentTiming {
  static readonly type = '[Incident] Update Impact Timing';
  constructor(
    public timing: ImpactTimings,
    public datetime: Date,
    public setNull: boolean = false
  ) {}
}

export class AddCommentToIncidentAction {
  static readonly type = '[Incident] Add Comment';
  constructor(public comment: string) {}
}

export class AssignResponderRoleAction {
  static readonly type = '[Incident] Assign Responder Role';
  constructor(
    public memberId: string,
    public roleId: string
  ) {}
}

export class UnassignResponderRoleAction {
  static readonly type = '[Incident] Unassing Responder Role';
  constructor(
    public assignmentId: string,
    public roleId: string,
    public memberId: string
  ) {}
}

export class IncidentTitleUpdatedEventAction {
  static readonly type = '[Event] Incident Title Updated';
  constructor(
    public incidentId: number,
    public newTitle: string
  ) {}
}

export class AddIncidentEventAction {
  static readonly type = '[Event] Add Incident Event';
  constructor(
    public incidentId: number,
    public event: IncidentEvent
  ) {}
}

export class LoadIncidentSchemasAction {
  static readonly type = '[Incident] Load Incident Schemas';
  constructor() {}
}

export class LoadIncidentSchemaVersionAction {
  static readonly type = '[Incident] Load Incident Schema Version';
  constructor(public schemaVersionId: string) {}
}

export class DeleteIncidentEventAction {
  static readonly type = '[Event] Delete Incident Event';
  constructor(public eventId: string) {}
}

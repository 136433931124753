import { StateContext, StateOperator } from '@ngxs/store';
import { Observable, catchError, of, tap } from 'rxjs';
import { IdentifiableEntities, ListEntities } from './list-entity';
import { patch } from '@ngxs/store/operators';
import {
  IPagination,
  PaginatedResponse,
  PaginationOptions,
  PaginationState,
} from './pagination';

export interface PaginatedListEntitiesState<T, P = PaginationState>
  extends ListEntities<T> {
  pagination: P;
}

export class PaginatedListEntities<T, P extends IPagination> {
  private pagination: P;
  private key: keyof T;

  constructor(key: keyof T, pagination: P) {
    this.pagination = pagination;
    this.key = key;
  }

  loadEntititesPaginated(
    ctx: StateContext<T>,
    paginationOptions: PaginationOptions,
    observable: Observable<PaginatedResponse<any>>,
    append = false
  ) {
    ctx.setState(
      setFields(this.key, {
        isLoading: true,
        loadingError: undefined,
        pagination: patch<PaginationState>(
          this.pagination.optionsToState(paginationOptions)
        ),
        ...(append ? {} : { entities: {} }),
      })
    );

    return observable.pipe(
      tap((response: PaginatedResponse<IdentifiableEntities<any>>) => {
        ctx.setState(
          setFields(this.key, {
            isLoading: false,
            ...this.pagination.responseToState(
              ctx.getState()[this.key] as PaginatedListEntitiesState<T, any>,
              response
            ),
          })
        );
      }),
      catchError((error: any) => {
        ctx.setState(
          setFields(this.key, {
            isLoading: false,
            loadingError: error.toString(),
          })
        );
        return of();
      })
    );
  }
}

export type PaginatedListEntityFields<T extends ListEntities<any>> = {
  [K in keyof PaginatedListEntitiesState<T>]?:
    | PaginatedListEntitiesState<T>[K]
    | StateOperator<any[K]>;
};

function setFields<T>(
  key: keyof T,
  fields: PaginatedListEntityFields<any>
): StateOperator<T> {
  return patch<any>({
    [key]: patch<PaginatedListEntityFields<any>>({
      ...fields,
    }),
  });
}

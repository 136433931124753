import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'v-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class VCardComponent {
  /**
   * Whether to center the card in the page
   */
  @Input() center: boolean = false;

  /**
   * How much padding to apply to the card
   */
  @Input() padding: 'sm' | 'md' | 'lg' = 'md';

  /**
   * The variant of the card
   */
  @Input() variant: 'neutral' | 'danger' | 'transparent' = 'neutral';

  @Input() clickable: boolean = false;

  @Input() fullHeight: boolean = false;
  @Input() fullWidth: boolean = false;
}

import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuItem } from 'src/app/services/menu/menuItem';
import { VButtonComponent } from '../buttons/button/button.component';
import { VMenuComponent } from '../menu/menu.component';
import { VMessageComponent } from '../message/message.component';

export type UserMetadata = {
  avatar: string;
  name: string;
  email: string;
  selectedOrganization?: string;
};

@Component({
  selector: 'v-sidemenu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    OverlayPanelModule,
    VMenuComponent,
    VMessageComponent,
    VButtonComponent,
  ],
  templateUrl: './sidemenu.component.html',
  styleUrl: './sidemenu.component.scss',
})
export class VSideMenuComponent {
  @Input() logoImage = '/assets/images/logos/incidite-full-color.svg';

  @Input() items: MenuItem[] = [];

  @Input() user?: UserMetadata;

  @Input() userMenuItems: MenuItem[] = [];

  @Input() additionalContent?: TemplateRef<any>;
}

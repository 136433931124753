<aside class="v-sidemenu">
  <header>
    <img [src]="logoImage" alt="Incidite Logo" />
  </header>

  <main>
    <v-menu [items]="items" size="large"></v-menu>
  </main>

  @if (additionalContent) {
    <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
  }

  <footer *ngIf="user">
    <div class="v-sidemenu-usercard">
      <img [src]="user.avatar" alt="User Image" />
      <div class="v-text-primary">
        <h3>{{ user.name }}</h3>
        <p>{{ user.email }}</p>
      </div>
      <aside *ngIf="userMenuItems.length > 0" #aside>
        <p-overlayPanel
          #userMenu
          [appendTo]="aside"
          styleClass="v-sidemenu-overlay">
          <v-menu
            [items]="userMenuItems"
            size="small"
            (selected)="userMenu.hide()"></v-menu>
        </p-overlayPanel>

        <a (click)="userMenu.toggle($event)" aria-hidden="true">
          <i class="pi pi-ellipsis-v v-text-primary"></i>
        </a>
      </aside>
    </div>

    <div class="v-sidemenu-footer">
      <div class="flex items-center">
        <i class="pi pi-building mr-1"></i>
        {{ user.selectedOrganization }}
      </div>
      <div class="mx-2">|</div>
      <a
        href="https://incidite.com/contact"
        target="_blank"
        class="text-gray-400 flex items-center">
        <i class="pi pi-question-circle mr-1 !text-sm"></i>
        Support
      </a>
    </div>
  </footer>
</aside>

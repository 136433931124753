import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export type BadgeVariant =
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'neutral';

export type BadgeSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'v-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class VBadgeComponent {
  @Input() variant: BadgeVariant = 'success';

  @Input() size: BadgeSize = 'medium';

  @Input() icon?: string;

  @Input() label: string = 'Badge';

  @Input() pressable: boolean = false;

  @Output() pressed = new EventEmitter<MouseEvent>();
}

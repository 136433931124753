<div class="v-sidebar" *ngIf="user$ | async as user">
  <v-sidemenu
    [items]="menuService.getMenuItems()"
    [user]="user"
    [userMenuItems]="(userMenuItems$ | async) || []"
    [additionalContent]="additionalSidebarContent"></v-sidemenu>
</div>

<ng-template #additionalSidebarContent>
  @if (subscription$ | async; as subscription) {
    @if (subscription.data?.canStartTrial) {
      <v-message severity="info" [vRequiredRoles]="['subscriptions.manager']">
        <h4>Free Trial available</h4>
        <p class="m-0 text-sm">
          Start your free trial now and get access to all features.
        </p>
        <v-button
          class="mt-3"
          size="small"
          variant="primary"
          [fullWidth]="true"
          (press)="goToSubscription()"
          label="Start Free Trial"></v-button>
      </v-message>
    }
    @if (subscription.data?.status === SubscriptionStatus.TRIALING) {
      <v-message severity="info" [vRequiredRoles]="['subscriptions.manager']">
        <h4>Upgrade Your Trial</h4>
        <p class="m-0 text-sm">
          Continue your access to all features by upgrading your trial.
        </p>
        <v-button
          class="mt-3"
          size="small"
          variant="primary"
          [fullWidth]="true"
          (press)="goToSubscription()"
          label="Upgrade Plan"></v-button>
      </v-message>
    }
  }
</ng-template>

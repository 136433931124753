export class LoadServices {
  static readonly type = '[Services] Load Services';
  constructor() {}
}

export class LoadServiceAction {
  static readonly type = '[Services] Load Service';
  constructor(public id: number) {}
}

export class UpdateServiceAction {
  static readonly type = '[Services] Update Service';
  constructor(
    public serviceId: number,
    public updateSpec: {
      name?: string;
      description?: string;
      ownerTeamPublicId?: number;
    }
  ) {}
}

export class DeleteServiceAction {
  static readonly type = '[Services] Delete Service';
  constructor(public serviceId: number) {}
}

import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, tap } from 'rxjs';
import { HasQuota } from 'src/app/helpers/quota';
import {
  Feature,
  Plan,
  SubscriptionPlan,
} from 'src/app/models/subscription-plan';
import { UpdateSubscriptionDialogComponent } from 'src/app/pages/internal/settings/pages/subscription/dialogs/update-subscription/update-subscription.component';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserState } from 'src/app/store/user/user.state';
import { Entity, loadEntity, NewEntity, saveEntity } from '../common/entity';
import {
  ListEntities,
  loadEntities,
  NewListEntities,
} from '../common/list-entity';
import {
  CancelSubscriptionAction,
  CancelSubscriptionDowngradeAction,
  ChangeSubscriptionAction,
  LoadAvailablePlansAction,
  LoadSubscriptionAction,
  UnCancelSubscriptionAction,
  UsageUpdatedAction,
} from './subscription.actions';
import { SubscriptionService } from './subscription.service';

export interface SubscriptionStateModel {
  subscription: Entity<SubscriptionPlan>;
  availablePlans: ListEntities<Plan>;
}

export type FeatureWithAvailability = Feature & {
  availability: FeatureAvailability;
};

export enum FeatureAvailability {
  // Feature is not available at all
  NOT_AVAILABLE,
  // Feature is not available in the current plan
  NOT_AVAILABLE_IN_PLAN,
  // Feature is available, but the quota is exceeded
  QUOTA_EXCEEDED,
  // Feature is available and the quota is not exceeded
  AVAILABLE,
}

@State<SubscriptionStateModel>({
  name: 'subscription',
  defaults: {
    subscription: NewEntity(),
    availablePlans: NewListEntities(),
  },
})
@Injectable()
export class SubscriptionState {
  store = inject(Store);
  toast = inject(ToastService);
  subscriptionService = inject(SubscriptionService);
  dialogService = inject(DialogService);

  t = inject(TranslateService);

  @Selector()
  static subscription(state: SubscriptionStateModel) {
    return state.subscription;
  }

  @Selector()
  static availablePlans(state: SubscriptionStateModel) {
    return state.availablePlans;
  }

  static feature(featureName: string) {
    return createSelector(
      [SubscriptionState],
      (state: SubscriptionStateModel): FeatureWithAvailability | undefined => {
        if (state.subscription.isLoading) return;

        const feature = state.subscription.data?.plan.features.find(
          f => f.name === featureName
        );

        if (!feature) {
          return {
            id: '',
            tier: 0,
            name: featureName,
            availability: FeatureAvailability.NOT_AVAILABLE_IN_PLAN,
          };
        }

        let availability = FeatureAvailability.AVAILABLE;

        if (feature.quota) {
          if (feature.quota.quota === 0) {
            // Feature is not available in the current plan
            availability = FeatureAvailability.NOT_AVAILABLE_IN_PLAN;
          } else if (!HasQuota(feature)) {
            // Feature is available, but the quota is exceeded
            availability = FeatureAvailability.QUOTA_EXCEEDED;
          }
        }

        return {
          ...feature,
          availability: availability,
        };
      }
    );
  }

  @Action(UsageUpdatedAction)
  usageUpdated(
    ctx: StateContext<SubscriptionStateModel>,
    { featureName, newUsage }: UsageUpdatedAction
  ) {
    const currentState = ctx.getState();

    if (!currentState.subscription.data) {
      return;
    }

    return ctx.setState(
      patch({
        subscription: patch({
          data: patch({
            details: currentState.subscription.data.details
              ? patch({
                  currentPlan: patch({
                    plan: patch({
                      features: updateItem<Feature>(
                        f => f.name === featureName,
                        patch({ usage: newUsage })
                      ),
                    }),
                  }),
                })
              : undefined,
            plan: patch({
              features: updateItem<Feature>(
                f => f.name === featureName,
                patch({ usage: newUsage })
              ),
            }),
          }),
        }),
      })
    );
  }

  @Action(LoadSubscriptionAction)
  getSubscription(
    ctx: StateContext<SubscriptionStateModel>,
    { withDetails }: LoadSubscriptionAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntity(
      ctx,
      'subscription',
      this.subscriptionService.getSubscription(organizationID, withDetails),
      true
    );
  }

  @Action(ChangeSubscriptionAction)
  changeSubscription(
    ctx: StateContext<SubscriptionStateModel>,
    { options }: ChangeSubscriptionAction
  ) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const ref = this.dialogService.open(UpdateSubscriptionDialogComponent, {
      closable: false,
      focusOnShow: false,
    });

    const hasActiveSubscription =
      ctx.getState().subscription.data?.plan.name !== 'free';

    let observable;

    if (!hasActiveSubscription) {
      observable = this.subscriptionService.createSubscription(
        organizationID,
        options
      );
    } else {
      observable = this.subscriptionService.changeSubscription(
        organizationID,
        options
      );
    }

    return saveEntity(ctx, 'subscription', observable).pipe(
      tap(() => {
        ref.close();
      }),
      catchError(err => {
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(CancelSubscriptionAction)
  cancelSubscription(ctx: StateContext<SubscriptionStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const ref = this.dialogService.open(UpdateSubscriptionDialogComponent, {
      closable: false,
      focusOnShow: false,
    });

    return saveEntity(
      ctx,
      'subscription',
      this.subscriptionService.cancelSubscription(organizationID)
    ).pipe(
      tap(() => {
        ref.close();
      }),
      catchError(err => {
        ref.close();
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(CancelSubscriptionDowngradeAction)
  cancelSubscriptionDowngrade(ctx: StateContext<SubscriptionStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const ref = this.dialogService.open(UpdateSubscriptionDialogComponent, {
      closable: false,
      focusOnShow: false,
    });

    return saveEntity(
      ctx,
      'subscription',
      this.subscriptionService.cancelSubscriptionDowngrade(organizationID)
    ).pipe(
      tap(() => {
        ref.close();
      }),
      catchError(err => {
        ref.close();
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(UnCancelSubscriptionAction)
  unCancelSubscription(ctx: StateContext<SubscriptionStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    const ref = this.dialogService.open(UpdateSubscriptionDialogComponent, {
      closable: false,
      focusOnShow: false,
    });

    return saveEntity(
      ctx,
      'subscription',
      this.subscriptionService.unCancelSubscription(organizationID)
    ).pipe(
      tap(() => {
        ref.close();
      }),
      catchError(err => {
        ref.close();
        this.toast.showError(err);
        throw err;
      })
    );
  }

  @Action(LoadAvailablePlansAction)
  loadAvailablePlans(ctx: StateContext<SubscriptionStateModel>) {
    const organizationID = this.store.selectSnapshot(
      UserState.getCurrentOrganizationID
    );

    if (!organizationID) throw new Error('No organization ID set');

    return loadEntities(
      ctx,
      'availablePlans',
      this.subscriptionService.getPlans(organizationID)
    );
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  DurationUnit,
  addMinutes,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { DataPoint } from 'src/app/models/statistics';
declare global {
  interface Window {
    Apex: any;
  }
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  markers: ApexMarkers; //ApexMarkers;
  stroke: ApexStroke; //ApexStroke;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: string[];
  labels: string[] | number[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-stats-card',
  standalone: true,
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
  imports: [CommonModule, NgApexchartsModule, TranslateModule],
})
export class StatsCardComponent implements OnInit, OnChanges {
  @ViewChild('chart', { static: false }) chart?: ChartComponent;

  @Input() header = '';
  @Input() subheader = '';
  @Input() unit: string = 'number';
  @Input() datapoints?: DataPoint[] = [];
  @Input() aggregation: 'last' | 'sum' | 'mean' = 'last';
  @Input() size: 'sm' | 'md' = 'sm';

  public currentValue() {
    if (!this.datapoints || this.datapoints.length === 0) {
      return '-';
    }

    let currentValue: number = 0;

    switch (this.aggregation) {
      case 'last':
        currentValue = this.datapoints[this.datapoints.length - 1].value;
        break;
      case 'sum':
        currentValue = this.datapoints
          .map(p => p.value)
          .reduce((p, c) => p + c);
        break;
      case 'mean':
        // when every datapoint is null we cannot calculate a mean value
        if (this.datapoints.every(dp => dp.value == 0)) {
          return '-';
        }
        currentValue =
          this.datapoints.map(p => p.value).reduce((p, c) => p + c) /
          this.datapoints.filter(dp => dp.value > 0).length;
    }

    switch (this.unit) {
      case 'minutes':
        // eslint-disable-next-line no-case-declarations
        const duration = intervalToDuration({
          start: new Date(),
          end: addMinutes(new Date(), currentValue),
        });

        // eslint-disable-next-line no-case-declarations
        let units: DurationUnit[] = [];

        if (duration.days) {
          units = ['days', 'hours'];
        } else if (duration.hours) {
          units = ['hours', 'minutes'];
        } else {
          units = ['minutes', 'seconds'];
        }

        return formatDuration(
          duration,
          currentValue === 0
            ? { format: ['minutes'], zero: true }
            : { format: units, zero: true }
        );
      default:
        return currentValue;
    }
  }

  public getFontSizeClass() {
    if (this.currentValue().toString().length > 10) {
      return this.size === 'md' ? 'text-lg' : 'text-md';
    } else {
      return this.size === 'md' ? 'text-3xl' : 'text-xl';
    }
  }

  ngOnInit() {
    this.calculateChart();
  }

  ngOnChanges(): void {
    this.calculateChart();
  }

  private calculateChart() {
    if (this.datapoints && this.datapoints.length > 2) {
      this.chartOptions.series![0].data = this.datapoints.map(p => p.value);
      this.chartOptions.yaxis!.max = undefined;
    }

    this.chart?.updateOptions({
      chart: {
        height: this.size === 'md' ? 60 : 40,
      },
    });
  }

  public chartOptions: Partial<ChartOptions> = {
    chart: {
      type: 'area',
      animations: {
        enabled: false,
      },
      height: this.size === 'md' ? 60 : 40,
      width: '100%',
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      colors: ['#3078db'],
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0.4,
        gradientToColors: ['#3078db'],
      },
    },
    yaxis: {},
    series: [
      {
        data: [],
      },
    ],
  };
}

import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Store } from '@ngxs/store';
import { SetRealtimeConnectionStateAction } from './store/misc/misc.actions';
import { CustomScalarLink } from './helpers/graphql';

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, store: Store): ApolloClientOptions<any> {
        const http = httpLink.create({
          uri: environment.gqlEndpoint,
          withCredentials: true,
        });

        // Create a WebSocket link:
        const ws = new GraphQLWsLink(
          createClient({
            url: environment.gqlEndpointWS,
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
          })
        );

        ws.client.on('connecting', () => {
          store.dispatch(new SetRealtimeConnectionStateAction('connecting'));
        });

        ws.client.on('closed', () => {
          store.dispatch(new SetRealtimeConnectionStateAction('closed'));
        });

        ws.client.on('connected', () => {
          store.dispatch(new SetRealtimeConnectionStateAction('connected'));
        });

        ws.client.on('error', () => {
          store.dispatch(new SetRealtimeConnectionStateAction('error'));
        });

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as {
              kind: string;
              operation: string;
            };
            return (
              kind === 'OperationDefinition' && operation === 'subscription'
            );
          },
          ws,
          http
        );

        return {
          link: ApolloLink.from([CustomScalarLink, link]),
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: {
              errorPolicy: 'all',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
        };
      },
      deps: [HttpLink, Store],
    },
  ],
})
export class GraphQLModule {}

<div>
  <!-- Stats -->
  <div
    class="grid grid-flow-col grid-nogutter gap-4 vops-stats"
    *ngIf="statisticsVM$ | async as vm">
    <article class="flex-1 min-w-300px v-card-bg rounded-lg overflow-hidden">
      <app-stats-card
        header="components.stats.activeIncidents.header"
        [size]="statCardSize"
        [datapoints]="vm.activeIncidents?.datapoints!"></app-stats-card>
    </article>
    <article class="flex-1 v-card-bg rounded-lg overflow-hidden">
      <app-stats-card
        header="components.stats.resolvedIncidents.header"
        [size]="statCardSize"
        subheader="components.stats.last7days"
        aggregation="sum"
        [datapoints]="vm.resolvedIncidents?.datapoints!"></app-stats-card>
    </article>
    <article class="flex-1 v-card-bg rounded-lg overflow-hidden">
      <app-stats-card
        header="components.stats.totalImpactDuration.header"
        [size]="statCardSize"
        subheader="components.stats.last7days"
        aggregation="sum"
        [unit]="vm.totalImpactDuration?.unit!"
        [datapoints]="vm.totalImpactDuration?.datapoints!"></app-stats-card>
    </article>
    <article class="flex-1 v-card-bg rounded-lg overflow-hidden">
      <app-stats-card
        header="components.stats.mtta.header"
        [size]="statCardSize"
        subheader="components.stats.last7days"
        aggregation="mean"
        [unit]="vm.mtta?.unit!"
        [datapoints]="vm.mtta?.datapoints!"></app-stats-card>
    </article>
    <article class="flex-1 v-card-bg rounded-lg overflow-hidden">
      <app-stats-card
        header="components.stats.mttr.header"
        [size]="statCardSize"
        subheader="components.stats.last7days"
        aggregation="mean"
        [unit]="vm.mttr?.unit!"
        [datapoints]="vm.mttr?.datapoints!"></app-stats-card>
    </article>
  </div>

  <div class="grid grid-cols-12 mt-6 flex-wrap gap-8">
    <div class="col-span-7" *ngIf="activeIncidentsVM$ | async as vm">
      <header class="flex items-center justify-between h-10">
        <h3 class="font-semibold">
          {{ 'pages.home.activeIncidents.header' | translate }}
        </h3>
        <v-button
          *ngIf="vm.activeIncidents.length > 0 || vm.isLoading"
          size="small"
          [label]="'pages.incidentList.createIncidentBtn' | translate"
          icon="pi pi-plus"
          (press)="newIncident()"
          [vRequiredRoles]="['incidents.manager']"></v-button>
      </header>
      <div class="mt-3">
        @if (vm.activeIncidents.length === 0 && !vm.isLoading) {
          <div
            class="border-dashed border-2 border-gray-800 rounded-lg p-8 flex flex-col items-center">
            <p class="text-subtle py-2">
              {{ 'pages.home.activeIncidents.nodata' | translate }}
            </p>
            <v-button
              [label]="
                'pages.home.activeIncidents.createIncidentBtn' | translate
              "
              icon="pi pi-plus"
              (press)="newIncident()"
              [vRequiredRoles]="['incidents.manager']"></v-button>
          </div>
        } @else {
          <v-data-display
            layout="list"
            [isLoading]="vm.isLoading || false"
            [gridColumns]="2"
            [showPaginator]="true"
            [paginatorRows]="activeIncidentsPaginationState.pageSize"
            [paginatorPage]="activeIncidentsPaginationState.page"
            [paginatorRowsPerPageOptions]="[3, 6, 10]"
            [paginatorTotalRecords]="vm.pagination?.totalRecords || 0"
            (paginatorPageChange)="onActiveIncidentsPageChange($event)">
            @for (incident of vm.activeIncidents; track incident.id) {
              <ng-template vDataItem>
                <app-active-incident
                  [incident]="incident"></app-active-incident>
              </ng-template>
            }
          </v-data-display>
        }
      </div>
    </div>
    <div class="col-span-5">
      <header class="flex items-center h-10">
        <h3 class="font-semibold">
          {{ 'pages.home.serviceStatus.header' | translate }}
        </h3>
      </header>

      <div class="mt-3" *ngIf="componentsVM$ | async as vm">
        @if (vm.isLoading) {
          <div>
            <p-skeleton
              [autosize]="true"
              height="4rem"
              borderRadius="10px"
              styleClass="mb-3"
              *ngFor="let i of [1, 2, 3]"></p-skeleton>
          </div>
        } @else {
          @if (vm.components.length === 0) {
            <div
              class="border-dashed border-2 border-gray-800 rounded-lg p-8 flex flex-col items-center">
              <p class="text-subtle py-2">
                {{ 'pages.home.serviceStatus.nodata' | translate }}
              </p>
              <v-button
                [label]="'pages.serviceList.createServiceBtn' | translate"
                icon="pi pi-plus"
                class="p-button-primary"
                [routerLink]="['/services/new']"
                [vRequiredRoles]="['services.manager']"></v-button>
            </div>
          } @else {
            <v-data-display
              layout="grid"
              [isLoading]="vm.isLoading || false"
              [gridColumns]="2"
              [showPaginator]="false"
              [groupingKeys]="[
                {
                  label: 'Team',
                  key: 'team'
                },
                {
                  label: 'Status',
                  key: 'state'
                }
              ]">
              >
              @for (component of vm.components; track component.id) {
                <ng-template
                  vDataItem
                  [groupingKeys]="{
                    team: {
                      label: component.owner!.name,
                      value: component.owner!.name
                    },
                    state: {
                      label: ServiceStateToText(component.state!),
                      value: component.state!
                    }
                  }">
                  <v-basic-data-item
                    (press)="goToService(component)"
                    [title]="component.name"
                    [subtitle]="component.description"
                    [fullHeight]="true"
                    [status]="
                      ServiceStateToStatus(component.state!)
                    "></v-basic-data-item>
                </ng-template>
              }
            </v-data-display>
          }
        }
      </div>
    </div>
  </div>
</div>

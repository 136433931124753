<div class="mb-2 flex justify-between items-center h-10" #toolbar>
  <div class="flex gap-2">
    @if (layout === 'list') {
      <v-icon-button
        icon="pi pi-th-large"
        variant="secondary"
        size="small"
        (press)="setLayout('grid')"></v-icon-button>
    } @else {
      <v-icon-button
        icon="pi pi-list"
        variant="secondary"
        size="small"
        (press)="setLayout('list')"></v-icon-button>
    }
    <v-button
      *ngIf="groupingKeys.length > 0"
      icon="pi pi-tag"
      size="small"
      variant="secondary"
      [label]="currentGroupingKey?.label || 'All'"
      (press)="groupingMenu.toggle($event)"></v-button>

    <p-overlayPanel
      #groupingMenu
      [appendTo]="toolbar"
      styleClass="v-data-display-overlay">
      <v-menu
        [items]="items"
        size="small"
        (selected)="groupingMenu.hide()"></v-menu>
    </p-overlayPanel>
  </div>
  <div *ngIf="showPaginator && paginatorTotalRecords > 0">
    <v-paginator
      [totalRecords]="paginatorTotalRecords"
      [rows]="paginatorRows"
      [page]="paginatorPage"
      [rowsPerPageOptions]="paginatorRowsPerPageOptions"
      (pageChange)="paginatorPageChange.emit($event)"></v-paginator>
  </div>
</div>

@if (elements.length === 0 && !isLoading) {
  <div class="flex flex-col items-center">
    <h3 class="mt-4 text-subtle">No data available</h3>
  </div>
}

@if (isLoading) {
  <div class="flex flex-col items-center">
    <p-skeleton height="2.3rem" width="100%" styleClass="my-3"></p-skeleton>
    <p-skeleton height="2.3rem" width="100%" styleClass="my-3"></p-skeleton>
    <p-skeleton height="2.3rem" width="100%" styleClass="my-3"></p-skeleton>
  </div>
}

@if (currentGroupingKey) {
  <div *ngFor="let group of groups | keyvalue; let $first = first">
    <h3
      class="font-medium pb-2"
      [ngClass]="{
        'pt-4': !$first
      }">
      {{ group.value.label }}
    </h3>
    <ul
      class="grid gap-4"
      [ngClass]="
        layout === 'grid' ? 'grid-cols-' + gridColumns : 'grid-cols-1'
      ">
      @for (item of group.value.elements; track item) {
        <ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
      }
    </ul>
  </div>
} @else {
  <ul
    class="grid gap-4"
    [ngClass]="layout === 'grid' ? 'grid-cols-' + gridColumns : 'grid-cols-1'">
    @for (item of elements; track item) {
      <ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
    }
  </ul>
}

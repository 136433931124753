import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { MenuItem } from 'src/app/services/menu/menuItem';
import { VButtonComponent } from '../buttons/button/button.component';
import { VIconButtonComponent } from '../buttons/icon-button/icon-button.component';
import { VMenuComponent } from '../menu/menu.component';
import { VPaginatorComponent } from '../paginator/paginator.component';
import { VDataItemDirective } from './items/data-item.directive';

export type GroupingKey = {
  label: string;
  key: string;
};

export type GroupingValue = {
  label: string;
  value: string;
};

export type Group = {
  label: string;
  elements: VDataItemDirective[];
};

@Component({
  selector: 'v-data-display',
  standalone: true,
  imports: [
    CommonModule,
    VIconButtonComponent,
    VButtonComponent,
    OverlayPanelModule,
    VMenuComponent,
    VPaginatorComponent,
    SkeletonModule,
  ],
  templateUrl: './data-display.component.html',
  styleUrl: './data-display.component.scss',
})
export class VDataDisplayComponent implements AfterViewInit {
  @ContentChildren(VDataItemDirective) elements!: QueryList<VDataItemDirective>;

  groupedElements: {
    [key: string]: {
      [key: string]: Group;
    };
  } = {};

  @Input() layout: 'list' | 'grid' = 'list';

  @Input() gridColumns = 3;

  @Input() isLoading = false;

  currentGroupingKey?: GroupingKey;

  @Input() groupingKeys: GroupingKey[] = [];

  // paginator options
  @Input() showPaginator = false;
  @Input() paginatorRows = 10;
  @Input() paginatorPage = 1;
  @Input() paginatorTotalRecords = 0;
  @Input() paginatorRowsPerPageOptions = [10, 20, 30];
  @Output() paginatorPageChange = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.elements.changes.subscribe(() => {
      this.buildGroups();
    });
    this.buildGroups();
  }

  buildGroups() {
    this.groupedElements = {};
    this.elements.forEach(element => {
      for (const [key, value] of Object.entries(element.groupingKeys)) {
        if (!this.groupedElements[key]) {
          this.groupedElements[key] = {};
        }

        if (!this.groupedElements[key][value.value]) {
          this.groupedElements[key][value.value] = {
            label: value.label,
            elements: [],
          };
        }

        this.groupedElements[key][value.value].elements.push(element);
      }

      element.groupingKeys$.subscribe(() => {
        console.log('TRIGGER BUILD');
        this.buildGroups();
      });
    });
  }

  get items(): MenuItem[] {
    const items: MenuItem[] = [
      {
        label: 'All',
        onClicked: () => {
          this.currentGroupingKey = undefined;
        },
      },
    ];

    for (const key of this.groupingKeys) {
      items.push({
        label: key.label,
        onClicked: () => {
          this.currentGroupingKey = key;
        },
      });
    }

    return items;
  }

  // This is a getter that returns data items grouped by the current grouping key
  get groups(): { [k: string]: Group } {
    return this.groupedElements[this.currentGroupingKey!.key];
  }

  public setLayout(layout: 'list' | 'grid') {
    this.layout = layout;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { SkeletonModule } from 'primeng/skeleton';
import { SharedCommonModule } from 'src/app/core/common.module';
import { ServiceStateComponent } from 'src/app/core/components/service-state/service-state.component';
import { StatsCardComponent } from 'src/app/core/components/stats-card/stats-card.component';
import { StatisticsStateModule } from 'src/app/store/statistics/statistics.state.module';
import { StatuspagesStateModule } from 'src/app/store/statuspages/statuspages.state.module';
import { IncidentSchemasService } from '../../../store/incidents/incidentSchemas.service';
import { IncidentsService } from '../../../store/incidents/incidents.service';
import { IncidentsState } from '../../../store/incidents/incidents.state';
import { ServicesService } from '../services/services.service';
import { ServicesState } from '../services/services.state';
import { ActiveIncidentComponent } from './components/active-incident/active-incident.component';
import { HomePageComponent } from './home.component';

@NgModule({
  declarations: [HomePageComponent],
  imports: [
    NgxsModule.forFeature([IncidentsState, ServicesState]),
    StatuspagesStateModule,
    StatisticsStateModule,
    ActiveIncidentComponent,
    CommonModule,
    StatsCardComponent,
    TranslateModule,
    SkeletonModule,
    SharedCommonModule,
    ServiceStateComponent,
  ],
  providers: [IncidentsService, ServicesService, IncidentSchemasService],
  bootstrap: [],
  exports: [],
})
export class HomeModule {}

<div
  class="v-badge text-nowrap"
  aria-hidden="true"
  (click)="pressable && pressed.emit($event)"
  [ngClass]="{
    'v-badge-neutral': variant === 'neutral',
    'v-badge-success': variant === 'success',
    'v-badge-warning': variant === 'warning',
    'v-badge-danger': variant === 'danger',
    'v-badge-info': variant === 'info',
    'v-badge-small': size === 'small',
    'v-badge-medium': size === 'medium',
    'v-badge-large': size === 'large',
    'v-pressable': pressable
  }">
  @if (icon) {
    <i [class]="'mr-2 pi ' + icon"></i>
  }
  {{ label }}
</div>
